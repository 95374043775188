.table {
	position: absolute;
	z-index: 10;
	top: 45vh;
	left: 50%;
	max-height: 42vh;
	overflow-y: auto;
	animation-name: TableAnimation;
	animation-duration: 2s;
	animation-timing-function: ease-in;
	padding-right: 1.1rem;
}
.table::-webkit-scrollbar {
	width: 1rem;
}
.table::-webkit-scrollbar-track :horizontal {
	visibility: hidden;
	border: none;
}
.table::-webkit-scrollbar-track-piece:vertical {
	border: 2px solid #a79cb4;
	border-radius: 8px;
}
.table::-webkit-scrollbar-thumb {
	background-color: #a79cb4;
	border-radius: 8px;
}
.table:-webkit-scrollbar-corner {
	visibility: hidden;
}
.white {
	padding-right: 1rem;
	color: #c4b9e2;
	text-align: right;
}
.dcolor {
	color: #c4b9e2;
	text-align: left;
}
.buttonStyle {
	position: absolute;
	z-index: 10;
	top: 89vh;
	left: 65%;
	color: white;
	margin-top: 1rem;
	background-color: transparent;
	padding: 0.5rem 2.5rem;
	cursor: pointer;
	border-radius: 3rem;
	border: 2px solid rgb(199, 168, 230);
}
.registered {
	padding-right: 1rem;
	color: #c4b9e2;
	vertical-align: 0px;
}
.eventTable {
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}
.color1 {
	color: #1A1765;
	padding: 0.2rem 8rem 0.2rem 0.5rem;
	background-color: #a79cb4;
}
.color2 {
	padding: 0.2rem 8rem 0.2rem 0.5rem;
	color: #1A1765;
	background-color: #E3D0FF;
}

@keyframes TableAnimation {
	0% {
		opacity: 0;
		top: 38vh;
	}
	52% {
		opacity: 0;
		top: 38vh;
	}
	100% {
		opacity: 1;
		top: 45vh;
	}
}
