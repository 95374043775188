.homepagemobileApp {
  text-align: center;
  background-color: white;
  scroll-behavior: smooth;
}

.homepagemobileApp > div > img {
  width: 100vw;
  display: block;
}

.room_description-enter {
  height: 0;
}
.room_description-enter-active,
.room_description-enter-done {
  height: var(--descheight);
  transition: height 1s;
}

.room_description-enter-active > div,
.room_description-enter-done > div {
  transition: transform 1s;
  transform: translateY(0%) !important;
  z-index: 0 !important;
}
/* 
.room_description-enter-active ~ div:nth-of-type(even),
.room_description-enter-done ~ div:nth-of-type(even) {
  transition: transform 1s;
  transform: translateY(var(--descheight));
} */

.room_description-exit,
.room_description-exit-active {
  height: 0;
  transition: height 1s;
}

.room_description-exit > div,
.room_description-exit-active > div {
  transition: transform 1s;
  z-index: 0 !important;
}
/* 
.room_description-exit ~ div:nth-of-type(even) {
  transition: transform 1s;
} */
