.cls1 {
  fill: url(#countdown-linear-gradient);
}
.cls2 {
  fill: url(#countdown-linear-gradient-2);
}
.cls3 {
  fill: #e998a2;
}
.cls4 {
  font-size: 109.19px;
  text-anchor: middle;
}
.cls4,
.cls8 {
  fill: #fff;
  font-family: Rheiborn_Sans_Clean, Rheiborn Sans Clean;
}
.cls5 {
  fill: #120a5a;
}
.cls6 {
  fill: url(#countdown-linear-gradient-3);
}
.cls7 {
  fill: url(#countdown-linear-gradient-4);
}
.cls8 {
  font-size: 27.3px;
}
.cls9 {
  fill: url(#countdown-linear-gradient-5);
}
.cls10 {
  fill: url(#countdown-linear-gradient-6);
}
.cls11 {
  fill: url(#countdown-linear-gradient-7);
}
.cls12 {
  fill: url(#countdown-linear-gradient-8);
}
.cls13 {
  fill: url(#countdown-linear-gradient-9);
}
.cls14 {
  fill: url(#countdown-linear-gradient-10);
}
.cls15 {
  fill: url(#countdown-linear-gradient-11);
}
.cls16 {
  fill: url(#countdown-linear-gradient-12);
}
