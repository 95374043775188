@media (max-width: 876px) {
  .formOuter {
    background-image: url("../images/Background.png");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form {
    position: absolute;
    z-index: 10;
    top: 15%;
    left: 8%;
  }

  .formInput {
    font-size: 1rem;
    color: rgb(199, 168, 230);
    width: 70vw;
    background-color: transparent;
    margin-bottom: 0.3rem;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid rgb(196, 134, 238);
    outline: none;
  }
  .heading {
    border-bottom: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  .formInput input {
    font-size: 0.8rem;
    color: rgb(199, 168, 230);
    padding-left: 4px;
    width: 64vw;
    background-color: transparent;
    border: none;
    outline: none;
  }
  .formInput input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .formInput::placeholder {
    color: rgb(199, 168, 230);
  }

  .formSubmit {
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 4px;
    position: relative;
    margin: 10px 0;
  }

  .formInput2 {
    font-size: 1rem;
    color: rgb(199, 168, 230);
    width: 34vw;
    background-color: transparent;
    margin-bottom: 0.3rem;
    margin-right: 10px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid rgb(196, 134, 238);
    outline: none;
    /* float: left; */
    display: inline-block;
  }
  .confirmpass {
    float: none;
    width: 40vw;
  }
  .formInput2 input {
    font-size: 0.8rem;
    color: rgb(199, 168, 230);
    padding-left: 5px;
    width: 27vw;
    background-color: transparent;
    border: none;
    outline: none;
  }
  .confirmpass input {
    width: 33vw;
  }
  .formInput2 input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .formInput2::placeholder {
    color: rgb(199, 168, 230);
  }

  .phone {
    width: 47vw;
  }
  .phone input {
    width: 39vw;
  }
  .Gender {
    width: 20vw;
  }
  .Gender input {
    width: 13vw;
  }
  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.55rem;
    color: red;
  }
}
