.Footer {
  width: 100vw;
  height: 35rem;
  position: relative;
  bottom: 0;
  background-color: #b8b1d8;
  padding: 2rem 0 1rem;
  --col-primary: #4b27a9;
  box-sizing: border-box;
  font-family: Quicksand;
  color: var(--col-primary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 90%;
}

.Footer > .middlediv {
  display: flex;
  justify-content: flex-start;
  /* padding: 0rem 2rem; */
  flex: 0.8;
}

.Footer a {
  text-decoration: none;
  color: inherit;
  height: fit-content;
  width: fit-content;
}

.Footer a:hover {
  opacity: 0.8;
}

.Homepagelinks,
.Otherlinks {
  border-left: 1px solid var(--col-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
  margin-left: 1.5em;
  height: 100%;
}

.Otherlinks {
  flex: 1;
}

.Homepagelinks a,
.Otherlinks a {
  padding: 0rem 0.6rem;
}

.Homepagelinks > *:not(:last-child) {
  height: 23%;
}

.thirddiv {
  border-top: 1px solid var(--col-primary);
  font-weight: 600;
}

.thirddiv {
  padding: 0.8rem 1.5rem;
}

.title {
  font-size: 2.2rem;
  margin: 0rem 1.5rem;
  border-bottom: 1px solid var(--col-primary);
  width: 30%;
}

.Footer ul {
  list-style-type: none;
}

.Activitieslinks {
  display: flex;
}

.Activitieslinks > a {
  width: 5em;
  box-sizing: border-box;
}

.Activitiessublinks li {
  font-weight: normal;
  margin-bottom: 0.3rem;
}

.letsconnect,
.register {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.register {
  margin-top: 1rem;
}

.connectlinks {
  width: 50%;
  display: flex;
  justify-content: space-between;
  font-size: 200%;
}

.register input {
  background-color: transparent;
  outline: none;
  border: 1px solid var(--col-primary);
  flex: 0.8;
  padding: 0.2rem;
  width: 40%;
  font-size: inherit;
}

.register input::placeholder {
  color: var(--col-primary);
  opacity: 0.7;
}
