.zoomin {
  transform: scale(5.95);
  transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
}

.zoomout {
  transform: scale(0.1);
  transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.changeframe {
  transition: transform-origin 1.3s ease-in-out, top 1.3s ease-in-out,
    left 1.3s ease-in-out;
}

.backgroundzoomin {
  transform: scale(2.5);
  transition: transform 1.5s cubic-bezier(0.32, 0, 0.67, 0);
  /* transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0); */
}

.backgroundzoomout {
  transform: scale(1);
  transition: transform 1.5s cubic-bezier(0.33, 1, 0.68, 1);
  /* transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1); */
}
