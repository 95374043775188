.Footer {
  width: 100vw;
  height: 50vh;
  position: relative;
  bottom: 0;
  background-color: #b8b1d8;
  padding: 2rem 3rem;
  --col-primary: #4b27a9;
  box-sizing: border-box;
  font-family: Quicksand;
  color: var(--col-primary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 95%;
}

.Footer a {
  text-decoration: none;
  color: inherit;
  height: fit-content;
}

.Footer a:hover {
  opacity: 0.8;
}

.Homepagelinks,
.Otherlinks {
  border-top: 2px solid var(--col-primary);
  display: flex;
  /* justify-content: space-between; */
  font-weight: 600;
}

.Homepagelinks > *:not(:last-child),
.Otherlinks > *:not(:last-child) {
  width: 23%;
  display: inline-block;
}

.Homepagelinks > *:last-child {
  /* .Otherlinks > *:last-child { */
  margin-left: auto;
}

.thirddiv {
  border-top: 2px solid var(--col-primary);
  font-weight: 600;
}

.Homepagelinks,
.thirddiv {
  padding: 0.8rem 0;
}

.Otherlinks {
  padding: 1.7rem 0;
}

.title {
  font-size: 2.2rem;
}

.Footer ul {
  list-style-type: none;
}

.Activitieslinks li:not(:first-child) {
  font-weight: normal;
  margin-top: 0.2em;
}

.letsconnect {
  width: 50%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.connectlinks {
  width: 60%;
  display: flex;
  justify-content: space-around;
  font-size: 1.8rem;
  padding-right: 1rem;
  border-right: 2px solid var(--col-primary);
}

.register {
  width: 50%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.register input {
  background-color: transparent;
  outline: none;
  border: 1px solid var(--col-primary);
  flex: 1;
  padding: 0.2rem;
}

.register label {
  margin: 0 2rem;
}
