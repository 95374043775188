* {
  margin: 0px;
  padding: 0px;
}

.background {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./Background.png");
  padding: 0;
  margin: 0;
}

.msgWrapper {
  position: relative;
  top: 30%;
  left: 10%;
  z-index: 10;
  background-color: #bdb3dc;
  width: 80%;
  text-align: center;
  border-radius: 1rem;
}
.regLine {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #1f1863;
  font-weight: 600;
}

.ktjName {
  font-size: 2rem;
  color: #1f1863;
  padding-bottom: 1.5rem;
}
.year {
  font-size: 2rem;
  color: white;
}

.Idbox {
  background-color: white;
  font-size: 1rem;
  width: 15rem;
  margin: auto;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-radius: 0.3rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
}

/* .ktjId{
    } */

.idIntial {
  color: #1f1863cf;
}

.note {
  width: 10rem;
  margin: auto;
  font-weight: 500;
  font-size: 1.2rem;
}

.explore {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-weight: 600;
  color: #1f1863;
}
