@media (min-width: 876px) {
  .signOuter {
    position: absolute;
    top: 45%;
    left: 60%;
    z-index: 10;
  }

  .formOuter {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .formSubmit {
    border-radius: 6px;
    width: 150px;
    font-size: 1.5rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 10px;
  }

  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.8rem;
    color: red;
  }
}
