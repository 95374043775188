@media (min-width: 876px) {
  .form {
    position: absolute;
    z-index: 10;
    top: 20%;
    left: 60%;
  }
  .forminner {
    display: flex;
    flex-direction: column;
  }
  .formInput {
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    width: 440px;
    background-color: transparent;
    margin-bottom: 10px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 5px solid rgb(196, 134, 238);
    outline: none;
  }
  .formInput input {
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    width: 400px;
    background-color: transparent;
    border: none;
    outline: none;
  }
  .formInput input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  #Gender {
    float: left;
  }
  .formInput::placeholder {
    color: rgb(199, 168, 230);
  }
  .sid input::placeholder {
    color: rgb(199, 168, 230);
  }
  .sid2 input::placeholder {
    color: rgb(199, 168, 230);
  }
  .sid {
    display: flex;
  }
  .phone-input {
    width: 230px;
    border-bottom: 5px solid rgb(196, 134, 238);
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 5px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
  }
  .formLogo {
    position: relative;
    top: 12.5px;
    /* border-bottom: 5px solid rgb(196, 134, 238); */
    padding: 5px;
  }
  .Gender {
    width: 110px;
    border-bottom: 5px solid rgb(196, 134, 238);
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 20px;
    margin-right: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
  }
  .sid2 {
    display: flex;
  }
  .input-flex {
    width: 170px;
    border-bottom: 5px solid rgb(196, 134, 238);
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 5px;
    margin-right: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
  }
  .formLogo2 {
    color: rgb(214, 185, 226);
    font-size: 1.2rem;
    position: relative;
    top: 12.5px;
    /* border-bottom: 5px solid rgb(196, 134, 238); */
    padding: 5px;
  }
  .formSubmit {
    border-radius: 6px;
    width: 150px;
    font-size: 1.5rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 10px;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
  }
  .formLogo {
    color: rgb(214, 185, 226);
    font-size: 1.2rem;
  }

  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.8rem;
    color: red;
  }

  .dropdownStyle {
    border: 0px;
    outline: 0px;
  }

  .red-icon {
    color: red;
  }
}
