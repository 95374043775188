@media (max-width: 876px) {
  .outer {
    background-image: url("../images/Background.png");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .signOuter {
    /* text-align: center; */
    padding-top: 20vh;
  }

  .formOuter {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .formInput {
    font-size: 1rem;
    color: rgb(199, 168, 230);
    width: 70vw;
    background-color: transparent;
    /* margin-bottom: 0.6rem; */
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid rgb(196, 134, 238);
    outline: none;
    margin-top: 1rem;
  }
  .formInput input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .formInput::placeholder {
    color: rgb(199, 168, 230);
  }

  .formSubmit {
    border-radius: 6px;
    font-size: 1rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 5px 20px;
  }
  .forgotPassword {
    border-radius: 6px;
    margin-top: 10px;
    font-size: 0.8rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 5px 20px;
  }

  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.8rem;
    color: red;
    margin-bottom: 1rem;
  }
}
