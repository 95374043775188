@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@font-face {
  font-family: "AvenirLTStd-Black";
  src: url(/static/media/AvenirLTStd-Black.7ce894e1.otf);
}
@font-face {
  font-family: "AvenirLTStd-Roman";
  src: url(/static/media/AvenirLTStd-Roman.0bfb049d.otf);
}
@font-face {
  font-family: "Rheiborn_Sans_Clean";
  src: url(/static/media/Rheiborn_Sans_Clean.34183443.otf);
}

body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 1024px) {
  html {
    font-size: 80%;
  }
}

@media (min-width: 1440px) {
  html {
    font-size: 120%;
  }
}

@media (max-width: 876px) {
  html {
    font-size: 100%;
  }
}

.App {
  position: relative;
  /* height: 100vh; */
  width: 100vw;
  /* overflow: hidden; */
  z-index: 0;
}

#root {
  overflow: hidden;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.normal {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  --left-adjust: 50.3938%;
  --top-adjust: 27.7248%;
}

.zoomin {
  -webkit-transform: scale(5.95);
          transform: scale(5.95);
  transition: -webkit-transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
  transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
  transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0), -webkit-transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
}

.zoomout {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  transition: -webkit-transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.changeframe {
  transition: top 1.3s ease-in-out,
    left 1.3s ease-in-out, -webkit-transform-origin 1.3s ease-in-out;
  transition: transform-origin 1.3s ease-in-out, top 1.3s ease-in-out,
    left 1.3s ease-in-out;
  transition: transform-origin 1.3s ease-in-out, top 1.3s ease-in-out,
    left 1.3s ease-in-out, -webkit-transform-origin 1.3s ease-in-out;
}

* {
  font-size: inherit;
}

.frame__content-enter {
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
}

.frame__content-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: all 1s;
}

.frame__content-exit {
  opacity: 1;
}

.frame__content-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  transition: all 0.5s;
}

.frame__content-exit-done {
  opacity: 0;
}

.spinLoader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -webkit-perspective: 800px;
          perspective: 800px;
}

.spinInner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.spinInner.one {
  left: 0%;
  top: 0%;
  -webkit-animation: rotate-one 1s linear infinite;
          animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #efeffa;
}

.spinInner.two {
  right: 0%;
  top: 0%;
  -webkit-animation: rotate-two 1s linear infinite;
          animation: rotate-two 1s linear infinite;
  border-right: 3px solid #efeffa;
}

.spinInner.three {
  right: 0%;
  bottom: 0%;
  -webkit-animation: rotate-three 1s linear infinite;
          animation: rotate-three 1s linear infinite;
  border-top: 3px solid #efeffa;
}

@-webkit-keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-webkit-keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-webkit-keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.Navbar_Navbar__2hRdE {
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #160f5d; */
    /* background-image: linear-gradient(#2a236a 0%, #2a236a 80%, #160f5d 50%); */
    right: 0;
    z-index: 100;
    /* padding-bottom: 3rem; */
}

.Navbar_Navbar__2hRdE img {
    margin-left: 1.5rem;
}

ul div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Navbar_NavbarList__1CFDY {
    text-align: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    box-sizing: border-box;
    justify-content: space-between;
    align-self: center;
    display: flex;
    /* transform: translateY(-25%); */
    flex-wrap: wrap;
    width: 80%;
    padding-right: 15.6rem;
    padding-bottom: 0.5rem;
}

.Navbar_NavbarList__1CFDY>li {
    display: inline-block;
    padding: 1rem 0rem;
    color: #c4b9e2;
    font-size: 1rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.Navbar_Navbar__2hRdE:not(.Navbar_active__3b0QJ):not(.Navbar_SignInBtn__2bWR8) ul li a:hover,
.Navbar_Navbar__2hRdE .Navbar_active__3b0QJ {
    border-bottom: 1px solid #c4b9e2;
}

.Navbar_Navbar__2hRdE .Navbar_active__3b0QJ button,
.Navbar_Navbar__2hRdE button:hover {
    background-color: #c4b9e2;
    color: white;
}

ul div {
    list-style: none;
}

ul li button {
    border: 1px solid #c4b9e2;
    color: #c4b9e2;
    font-size: 1rem;
    border-radius: 8px;
    font-weight: 600;
    background-color: transparent;
    padding: 0.2rem 0.8rem;
}

.Navbar_SignInBtn__2bWR8 {
    border-radius: 8px 0px 0px 8px;
    border-right: none;
}

.Navbar_SignUpBtn__qzvR0 {
    border-radius: 0px 8px 8px 0px;
}

.Navbar_SideDrawerBtn__2R_CB {
    display: none;
    font-size: 1.5rem;
    color: white;
}

@media screen and (max-width: 767px) {
    .Navbar_Navbar__2hRdE {
        justify-content: flex-start;
    }
    .Navbar_NavbarList__1CFDY {
        display: none;
    }
    .Navbar_SideDrawerBtn__2R_CB {
        display: block;
    }
    .Navbar_Hide__3XlsI {
        display: none;
    }
    .Navbar_Show__MKIzh {
        display: block;
    }
}
.Coming_back_button__3jmHd {
	position: absolute;
	z-index: 10;
	top: 90vh;
	left: 3%;
	padding: 0.5rem 2rem;
	border-radius: 9rem;
	cursor: pointer;
	outline: none;
	background-color: transparent;
	color: #c4b9e2;
	border: 0.1em solid #c4b9e2;
}
.Coming_back_button__3jmHd:hover {
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
}
.Coming_back_button__3jmHd:active {
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
}

.bgvideo {
  position: relative;
  height: 100vh;
  width: 100%;
  background-position: center;
  top: 0%;
  left: 0%;
  opacity: 1;
}
.bgvideo video {
  height: 100vh;
}
.bgvideo_content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Footer_Footer__IrRCd {
  width: 100vw;
  height: 50vh;
  position: relative;
  bottom: 0;
  background-color: #b8b1d8;
  padding: 2rem 3rem;
  --col-primary: #4b27a9;
  box-sizing: border-box;
  font-family: Quicksand;
  color: var(--col-primary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 95%;
}

.Footer_Footer__IrRCd a {
  text-decoration: none;
  color: inherit;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Footer_Footer__IrRCd a:hover {
  opacity: 0.8;
}

.Footer_Homepagelinks__2n40p,
.Footer_Otherlinks__3Z-HN {
  border-top: 2px solid var(--col-primary);
  display: flex;
  /* justify-content: space-between; */
  font-weight: 600;
}

.Footer_Homepagelinks__2n40p > *:not(:last-child),
.Footer_Otherlinks__3Z-HN > *:not(:last-child) {
  width: 23%;
  display: inline-block;
}

.Footer_Homepagelinks__2n40p > *:last-child {
  /* .Otherlinks > *:last-child { */
  margin-left: auto;
}

.Footer_thirddiv__XVLyf {
  border-top: 2px solid var(--col-primary);
  font-weight: 600;
}

.Footer_Homepagelinks__2n40p,
.Footer_thirddiv__XVLyf {
  padding: 0.8rem 0;
}

.Footer_Otherlinks__3Z-HN {
  padding: 1.7rem 0;
}

.Footer_title__16M1V {
  font-size: 2.2rem;
}

.Footer_Footer__IrRCd ul {
  list-style-type: none;
}

.Footer_Activitieslinks__2yjkm li:not(:first-child) {
  font-weight: normal;
  margin-top: 0.2em;
}

.Footer_letsconnect__3v-Po {
  width: 50%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.Footer_connectlinks__1F5dv {
  width: 60%;
  display: flex;
  justify-content: space-around;
  font-size: 1.8rem;
  padding-right: 1rem;
  border-right: 2px solid var(--col-primary);
}

.Footer_register__1Cu8G {
  width: 50%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.Footer_register__1Cu8G input {
  background-color: transparent;
  outline: none;
  border: 1px solid var(--col-primary);
  flex: 1 1;
  padding: 0.2rem;
}

.Footer_register__1Cu8G label {
  margin: 0 2rem;
}

@media (min-width: 876px) {
    .signOuter {
        position: absolute;
        top: 45%;
        left: 60%;
        z-index: 10;
    }

    .formOuter {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .formSubmit {
        border-radius: 6px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 1rem;
        background-color: transparent;
        color: rgb(214, 185, 226);
        border: 3px solid rgb(220, 195, 236);
        padding: 10px;
    }

    .red-text-field {
        border-bottom: 3px solid red;
    }

    .Email {

        font-size: 1.1rem;
        color: rgb(199, 168, 230);
        padding: 5px;
        width: 440px;
        background-color: transparent;
        margin-bottom: 10px;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        border-bottom: 5px solid rgb(196, 134, 238);
        outline: none;
    }

    .Email::-webkit-input-placeholder {
        color: rgb(199, 168, 230);
        outline: none;
    }

    .Email:-ms-input-placeholder {
        color: rgb(199, 168, 230);
        outline: none;
    }

    .Email::placeholder {
        color: rgb(199, 168, 230);
        outline: none;
    }
}
.SideDrawer_SideDrawer__2tmxU {
  width: 80%;
  display: none;
  background-color: #2e2869;
  color: #c4b9e2;
  position: absolute;
  left: 0;
  min-height: 100vh;
  top: 0;
  padding: 1rem;
  transition: 0.4s ease-in-out;

  flex-direction: column;
  justify-content: space-between;
}
.SideDrawer_KTJHeading__3z-ja {
  text-align: center;
  letter-spacing: 10px;
  font-weight: bold;
  width: 80%;
  box-sizing: border-box;
  display: inline-block;
  padding: 1rem;
  background-color: white;
  color: black;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
}

.SideDrawer_SideDrawer__2tmxU > div {
  padding: 1rem;
}
.SideDrawer_SideDrawerCloseBtn__1tmUw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .SideDrawer_SideDrawer__2tmxU {
    display: flex;
  }

  .SideDrawer_Hide__TbOrY {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .SideDrawer_Show__25I4i {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

.Frame0_container__2C8Ez {
  background-image: url(/static/media/background.adbac1d5.png);
  background-size: 100% 100%;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  opacity: 1;
}

.Frame0_Homepage__2ZHjt {
  --scale: 1000%;
  height: var(--scale);
  width: var(--scale);
  font-size: var(--scale);
  top: calc(var(--top-adjust) * -9);
  left: calc(var(--left-adjust) * -9);
  position: relative;
  -webkit-transform-origin: var(--left-adjust) var(--top-adjust);
          transform-origin: var(--left-adjust) var(--top-adjust);
  will-change: contents;
}

.Frame0_normal__2qLx3 {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  --left-adjust: 50.3938%;
  --top-adjust: 27.7248%;
}

.Frame0_homepagebackground__1ELQH {
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transform-origin: center 0%;
          transform-origin: center 0%;
}

.Frame0_buildingsvg__3Esa_,
.Frame0_buildingsvg__3Esa_ > div,
.Frame0_buildingsvg__3Esa_ > div > svg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Frame0_imgcontainer__2NV-u {
  height: 100%;
  width: 100%;
  position: absolute;
}

.Frame0_imgcontainer__2NV-u > img {
  width: 100%;
  height: 100%;
}

.Frame0_starssvg__3IA93 {
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% 58%,
    69% 58%,
    69% 38%,
    31% 38%,
    27% 58%,
    0 58%
  );
          clip-path: polygon(
    0 0,
    100% 0,
    100% 58%,
    69% 58%,
    69% 38%,
    31% 38%,
    27% 58%,
    0 58%
  );
}

.Frame0_starssvg__3IA93 > img {
  width: 100%;
  height: unset;
  opacity: 0.34;
  -webkit-transform: scale(1.2) rotate(45deg);
          transform: scale(1.2) rotate(45deg);
  -webkit-animation: Frame0_rotate__5FonY 20s linear infinite;
          animation: Frame0_rotate__5FonY 20s linear infinite;
}

.Frame0_starssvg__3IA93 > div {
  background-image: url(/static/media/stars.f8578ee9.png);
  background-size: 100%;
  opacity: 0.35;
  background-position: center center;
  --scaledvh: 110vh;
  --scaledvw: 110vw;
  height: max(var(--scaledvh), var(--scaledvw));
  width: max(var(--scaledvh), var(--scaledvw));
  position: relative;
  top: min(
    calc(60vh - calc(var(--scaledvw) / 2)),
    calc(60vh - calc(var(--scaledvh) / 2))
  );
  left: min(
    calc(50vw - calc(var(--scaledvh) / 2)),
    calc(50vw - calc(var(--scaledvw) / 2))
  );
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  -webkit-animation: Frame0_rotate__5FonY 36s linear infinite;
          animation: Frame0_rotate__5FonY 36s linear infinite;
}

.Frame0_clouds__2rLlt > div {
  background-image: url(/static/media/clouds.16641129.png);
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  -webkit-animation: Frame0_cloudtranslate__1yjbL 36s linear infinite;
          animation: Frame0_cloudtranslate__1yjbL 36s linear infinite;
}

@-webkit-keyframes Frame0_cloudtranslate__1yjbL {
  from {
    background-position-x: 100vw;
  }
  to {
    background-position-x: 0;
  }
}

@keyframes Frame0_cloudtranslate__1yjbL {
  from {
    background-position-x: 100vw;
  }
  to {
    background-position-x: 0;
  }
}

@-webkit-keyframes Frame0_rotate__5FonY {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}

@keyframes Frame0_rotate__5FonY {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}

.Frame0_spacetowerlight__4v3qG > img {
  opacity: 0.6;
  -webkit-animation: Frame0_flicker__XLhzs 5s linear infinite;
          animation: Frame0_flicker__XLhzs 5s linear infinite;
}

@-webkit-keyframes Frame0_flicker__XLhzs {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  6% {
    opacity: 1;
  }
  7% {
    opacity: 0;
  }
  8% {
    opacity: 0;
  }
  9% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  11% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Frame0_flicker__XLhzs {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  6% {
    opacity: 1;
  }
  7% {
    opacity: 0;
  }
  8% {
    opacity: 0;
  }
  9% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  11% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Window_window__1dbDs {
  background-color: black;
  position: absolute;
}
.Window_window__1dbDs video {
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  object-fit: fill;
}

.Frame12_container__23CyT {
  --left-width: 60.7%;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transform: scale(0.0168);
          transform: scale(0.0168);
  top: -21.9%;
  left: 0.384%;
  display: flex;
}

.Frame12_left__3-3Vq,
.Frame12_right__1-epa {
  height: 100%;
  display: inline-block;
  position: relative;
}

.Frame12_left__3-3Vq {
  width: var(--left-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #392d76; */
}

.Frame12_right__1-epa {
  width: calc(100% - var(--left-width));
  background-color: #221270;
  --window-height: 40%;
}

.Frame12_window1__2Ee56 {
  height: var(--window-height);
  width: 75%;
  top: 5em;
  right: 0;
}

.Frame12_window2__3wFGI {
  height: var(--window-height);
  width: 85%;
  bottom: 0;
}

.Content_aboutUs__1NQMd {
  font-size: 29rem;
  font-weight: bold;
  color: #c4b9e2;
}
.Content_left_content__13qTW {
  position: absolute;
  box-sizing: border-box;
  width: 75%;
  align-items: center;
  padding: 10rem 5rem;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 5rem;
}
.Content_left_content_firstDiv__1HFwv {
  display: flex;
  width: 95%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
}
.Content_left_content_firstDiv__1HFwv > div {
  display: inline-block;
}
.Content_left_content__13qTW img {
  width: 200%;
  height: 200%;
}
.Content_Video2__39Mgv {
  width: 12em;
  height: 12em;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
}
.Content_Video2__39Mgv video {
  position: absolute;
  top: -15%;
  left: -50%;
  width: 200%;
  height: 130%;
  padding: 0;
  margin: 0;
}

.Button_Button__tZ5cC {
  padding: 6rem 7rem;
  border-radius: 7rem;
  cursor: pointer;
  outline: none;
  margin-top: 1em;
  float: right;
  margin-right: 2em;
  border: 0.1em solid #c4b9e2;
}

.Button_primary__1spLS,
.Button_secondary__t9zIR:hover {
  background-color: #c4b9e2;
  color: #4d4579;
}

.Button_secondary__t9zIR,
.Button_primary__1spLS:hover {
  background-color: transparent;
  color: #c4b9e2;
}

.Description_Description__LGOGR {
  color: #c4b9e2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  padding: 1em 1em;
  max-height: 12em;
  overflow-y: auto;
  display: inline-block;
  scrollbar-width: auto;
  scrollbar-color: #c4b9e2 #392d76;
  font-size: 0.9em;
  border: 1rem solid rgba(255, 255, 255, 0.1);
}
.Description_Description__LGOGR::-webkit-scrollbar {
  border-radius: 60px;
  width: 10rem;
  display: block;
}

.Description_Description__LGOGR::-webkit-scrollbar-track {
  background: transparent;
}

.Description_Description__LGOGR::-webkit-scrollbar-thumb {
  outline: none;
  border-radius: 60px;
  background: #c4b9e2;
}
.Description_Description__LGOGR::-webkit-scrollbar-track-piece:end,
.Description_Description__LGOGR::-webkit-scrollbar-track-piece:start {
  background: transparent;
  border: 10px solid #c4b9e2;
  border-radius: 60px;
  margin-bottom: 20rem;
}

.Frame34_container__vmyrF {
  --left-width: 50%;
  position: absolute;
  height: 100%;
  display: flex;
  width: 100%;
  -webkit-transform: scale(0.0168);
          transform: scale(0.0168);
  top: -16.1%;
  left: 3.248%;
}

.Frame34_left__1Ym5Y,
.Frame34_right__2uh5p {
  height: 100%;
  display: inline-block;
  position: relative;
}

.Frame34_left__1Ym5Y {
  width: var(--left-width);
  /* background-color: #292268; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Frame34_right__2uh5p {
  width: calc(100% - var(--left-width));
  background-color: #5c3bb1;
  --window-height: 36%;
}

.Frame34_window1__dYKvn {
  height: var(--window-height);
  width: 60%;
  top: 5em;
  right: 0;
}

.Frame34_window2__2h0bI {
  height: var(--window-height);
  width: 60%;
  bottom: 9%;
  left: 7em;
}

.Frame56_container__2aiZ1 {
  --left-width: 44.4%;
  position: absolute;
  height: 100%;
  display: flex;
  width: 100%;
  -webkit-transform: scale(0.0168);
          transform: scale(0.0168);
  top: -9.5%;
  left: 4.358%;
}

.Frame56_left__15chQ,
.Frame56_right__22QXJ {
  height: 100%;
  display: inline-block;
  position: relative;
}

.Frame56_left__15chQ {
  width: var(--left-width);
  background-color: #6242b4;
  --window-height: 39%;
}

.Frame56_right__22QXJ {
  width: calc(100% - var(--left-width));
  /* background-color: #120a5a; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Frame56_window1__12lno {
  height: var(--window-height);
  width: 80%;
  top: 7em;
  right: 0;
}

.Frame56_window2__4TCU2 {
  height: var(--window-height);
  width: 80%;
  right: 0;
  bottom: 0;
}
.Frame56_right__22QXJ input:hover {
  border-bottom: 10px solid white;
}

.Frame56_frameEmail__2XDhz {
  margin-top: 1em;
  margin-left: 1em;
  color: #c4b9e2;
  display: inline-block;
}
.Frame56_inputEmail__2Fbjy {
  background-color: #c4b9e2;
  padding: 6rem 7rem;
  width: 60%;
  outline: none;
  border: none;
}
.Frame56_inputEmail__2Fbjy:valid {
  background-color: #c4b9e2;
}

.Countdown_cls1__159Ff {
  fill: url(#countdown-linear-gradient);
}
.Countdown_cls2__TlnTM {
  fill: url(#countdown-linear-gradient-2);
}
.Countdown_cls3__2DMId {
  fill: #e998a2;
}
.Countdown_cls4__1_w9Z {
  font-size: 109.19px;
  text-anchor: middle;
}
.Countdown_cls4__1_w9Z,
.Countdown_cls8__3HarC {
  fill: #fff;
  font-family: Rheiborn_Sans_Clean, Rheiborn Sans Clean;
}
.Countdown_cls5__23Swt {
  fill: #120a5a;
}
.Countdown_cls6__6gLjv {
  fill: url(#countdown-linear-gradient-3);
}
.Countdown_cls7__3YPrJ {
  fill: url(#countdown-linear-gradient-4);
}
.Countdown_cls8__3HarC {
  font-size: 27.3px;
}
.Countdown_cls9__34lAl {
  fill: url(#countdown-linear-gradient-5);
}
.Countdown_cls10__2Gdyk {
  fill: url(#countdown-linear-gradient-6);
}
.Countdown_cls11__ONZxg {
  fill: url(#countdown-linear-gradient-7);
}
.Countdown_cls12__3OCqU {
  fill: url(#countdown-linear-gradient-8);
}
.Countdown_cls13__1HKR6 {
  fill: url(#countdown-linear-gradient-9);
}
.Countdown_cls14__26K79 {
  fill: url(#countdown-linear-gradient-10);
}
.Countdown_cls15__kuNPv {
  fill: url(#countdown-linear-gradient-11);
}
.Countdown_cls16__3bNYu {
  fill: url(#countdown-linear-gradient-12);
}

.Sidebar_navbar__2Ta-g {
  display: inline-block;
  position: absolute;
  top: 28vh;
  left: 2vw;
  height: 50%;
  z-index: 100;
}
.Sidebar_sidebarcls1__3VvFt,
.Sidebar_sidebarcls2__-ycP2,
.Sidebar_sidebarcls3__3bbbk,
.Sidebar_sidebarcls4__1HEFa,
.Sidebar_sidebarcls5__29k2f,
.Sidebar_sidebarcls6__2SEGn,
.Sidebar_sidebarcls7__3mIou {
  stroke: #c597e6;
  stroke-miterlimit: 10;
  cursor: pointer;
}
.Sidebar_sidebarcls1__3VvFt {
  fill: url("#sidebar-radial-gradient");
}
.Sidebar_sidebarcls2__-ycP2 {
  fill: url("#sidebar-radial-gradient-2");
}
.Sidebar_sidebarcls3__3bbbk {
  fill: url("#sidebar-radial-gradient-3");
}
.Sidebar_sidebarcls4__1HEFa {
  fill: url("#sidebar-radial-gradient-4");
}
.Sidebar_sidebarcls5__29k2f {
  fill: url("#sidebar-radial-gradient-5");
}
.Sidebar_sidebarcls6__2SEGn {
  fill: url("#sidebar-radial-gradient-6");
}
.Sidebar_sidebarcls7__3mIou {
  fill: url("#sidebar-radial-gradient-7");
}
.Sidebar_tooltip__Z-Yhe {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  left: 1.5rem;
  font-size: 0.8rem;
  color: #c597e6;
  line-height: 1;
  opacity: 0;
  font-weight: 600;
  transition: opacity 0.3s ease;
}
.Sidebar_tooltipcontainer__2BXZr {
  height: 48.7%;
  top: 28.7vh;
}

.Homepage_zoomin__3GnAa {
  -webkit-transform: scale(5.95);
          transform: scale(5.95);
  transition: -webkit-transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
  transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
  transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0), -webkit-transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
}

.Homepage_zoomout__19o3_ {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  transition: -webkit-transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.Homepage_changeframe__UITPu {
  transition: top 1.3s ease-in-out,
    left 1.3s ease-in-out, -webkit-transform-origin 1.3s ease-in-out;
  transition: transform-origin 1.3s ease-in-out, top 1.3s ease-in-out,
    left 1.3s ease-in-out;
  transition: transform-origin 1.3s ease-in-out, top 1.3s ease-in-out,
    left 1.3s ease-in-out, -webkit-transform-origin 1.3s ease-in-out;
}

.Homepage_backgroundzoomin__252_S {
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
  transition: -webkit-transform 1.5s cubic-bezier(0.32, 0, 0.67, 0);
  transition: transform 1.5s cubic-bezier(0.32, 0, 0.67, 0);
  transition: transform 1.5s cubic-bezier(0.32, 0, 0.67, 0), -webkit-transform 1.5s cubic-bezier(0.32, 0, 0.67, 0);
  /* transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0); */
}

.Homepage_backgroundzoomout__14Zcu {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 1.5s cubic-bezier(0.33, 1, 0.68, 1);
  transition: transform 1.5s cubic-bezier(0.33, 1, 0.68, 1);
  transition: transform 1.5s cubic-bezier(0.33, 1, 0.68, 1), -webkit-transform 1.5s cubic-bezier(0.33, 1, 0.68, 1);
  /* transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1); */
}

.Table_table__2JtFT {
	position: absolute;
	z-index: 10;
	top: 45vh;
	left: 50%;
	max-height: 42vh;
	overflow-y: auto;
	-webkit-animation-name: Table_TableAnimation__1LuZJ;
	        animation-name: Table_TableAnimation__1LuZJ;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
	padding-right: 1.1rem;
}
.Table_table__2JtFT::-webkit-scrollbar {
	width: 1rem;
}
.Table_table__2JtFT::-webkit-scrollbar-track :horizontal {
	visibility: hidden;
	border: none;
}
.Table_table__2JtFT::-webkit-scrollbar-track-piece:vertical {
	border: 2px solid #a79cb4;
	border-radius: 8px;
}
.Table_table__2JtFT::-webkit-scrollbar-thumb {
	background-color: #a79cb4;
	border-radius: 8px;
}
.Table_table__2JtFT:-webkit-scrollbar-corner {
	visibility: hidden;
}
.Table_white__3krKt {
	padding-right: 1rem;
	color: #c4b9e2;
	text-align: right;
}
.Table_dcolor__1I1Wz {
	color: #c4b9e2;
	text-align: left;
}
.Table_buttonStyle__Jz_uG {
	position: absolute;
	z-index: 10;
	top: 89vh;
	left: 65%;
	color: white;
	margin-top: 1rem;
	background-color: transparent;
	padding: 0.5rem 2.5rem;
	cursor: pointer;
	border-radius: 3rem;
	border: 2px solid rgb(199, 168, 230);
}
.Table_registered__3JJgc {
	padding-right: 1rem;
	color: #c4b9e2;
	vertical-align: 0px;
}
.Table_eventTable__31HoT {
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}
.Table_color1__1zF7s {
	color: #1A1765;
	padding: 0.2rem 8rem 0.2rem 0.5rem;
	background-color: #a79cb4;
}
.Table_color2__Bj0Pj {
	padding: 0.2rem 8rem 0.2rem 0.5rem;
	color: #1A1765;
	background-color: #E3D0FF;
}

@-webkit-keyframes Table_TableAnimation__1LuZJ {
	0% {
		opacity: 0;
		top: 38vh;
	}
	52% {
		opacity: 0;
		top: 38vh;
	}
	100% {
		opacity: 1;
		top: 45vh;
	}
}

@keyframes Table_TableAnimation__1LuZJ {
	0% {
		opacity: 0;
		top: 38vh;
	}
	52% {
		opacity: 0;
		top: 38vh;
	}
	100% {
		opacity: 1;
		top: 45vh;
	}
}


@media (min-width: 876px) {
  .signOuter {
    position: absolute;
    top: 45%;
    left: 60%;
    z-index: 10;
  }

  .formOuter {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .formSubmit {
    border-radius: 6px;
    width: 150px;
    font-size: 1.5rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 10px;
  }

  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.8rem;
    color: red;
  }
}


@media (min-width: 876px) {
  .form {
    position: absolute;
    z-index: 10;
    top: 20%;
    left: 60%;
  }
  .forminner {
    display: flex;
    flex-direction: column;
  }
  .formInput {
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    width: 440px;
    background-color: transparent;
    margin-bottom: 10px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 5px solid rgb(196, 134, 238);
    outline: none;
  }
  .formInput input {
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    width: 400px;
    background-color: transparent;
    border: none;
    outline: none;
  }
  .formInput input::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput input:-ms-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  #Gender {
    float: left;
  }
  .formInput::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
  }
  .formInput:-ms-input-placeholder {
    color: rgb(199, 168, 230);
  }
  .formInput::placeholder {
    color: rgb(199, 168, 230);
  }
  .sid input::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
  }
  .sid input:-ms-input-placeholder {
    color: rgb(199, 168, 230);
  }
  .sid input::placeholder {
    color: rgb(199, 168, 230);
  }
  .sid2 input::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
  }
  .sid2 input:-ms-input-placeholder {
    color: rgb(199, 168, 230);
  }
  .sid2 input::placeholder {
    color: rgb(199, 168, 230);
  }
  .sid {
    display: flex;
  }
  .phone-input {
    width: 230px;
    border-bottom: 5px solid rgb(196, 134, 238);
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 5px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
  }
  .formLogo {
    position: relative;
    top: 12.5px;
    /* border-bottom: 5px solid rgb(196, 134, 238); */
    padding: 5px;
  }
  .Gender {
    width: 110px;
    border-bottom: 5px solid rgb(196, 134, 238);
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 20px;
    margin-right: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
  }
  .sid2 {
    display: flex;
  }
  .input-flex {
    width: 170px;
    border-bottom: 5px solid rgb(196, 134, 238);
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 5px;
    margin-right: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
  }
  .formLogo2 {
    color: rgb(214, 185, 226);
    font-size: 1.2rem;
    position: relative;
    top: 12.5px;
    /* border-bottom: 5px solid rgb(196, 134, 238); */
    padding: 5px;
  }
  .formSubmit {
    border-radius: 6px;
    width: 150px;
    font-size: 1.5rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 10px;
    text-align: center;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-top: 1rem;
  }
  .formLogo {
    color: rgb(214, 185, 226);
    font-size: 1.2rem;
  }

  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.8rem;
    color: red;
  }

  .dropdownStyle {
    border: 0px;
    outline: 0px;
  }

  .red-icon {
    color: red;
  }
}

*{
  margin:0px;
  padding:0px;
}

.signupMess_background__1Yn35 {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/static/media/Background2.53d63e2e.png);
    padding: 0;
    margin:0;
  }

  .signupMess_msgWrapper__gp6w4{
    position: relative;
    top: 25%;
    left: 60%;
    z-index:10;
    background-color:#BDB3DC ;
    width:23rem;
    text-align: center;
    border-radius: 1rem;

  }
  .signupMess_regLine__2n7Qb{
      padding-top:1rem;
      padding-bottom:1rem;
      color:#1F1863;
      font-weight: 600;
  }

  .signupMess_ktjName__1VoH-{
     font-size:2rem;
     color:#1F1863;
     padding-bottom: 1.5rem;
  }
  .signupMess_year__4-tif{
    font-size:2rem;
    color:white;
  }

  .signupMess_Idbox__3Zg7U{
      background-color: white;
      font-size: 1rem;
      width:15rem;
      margin:auto;
      padding-top:0.3rem;
      padding-bottom:0.3rem;
      border-radius:0.3rem;
      font-weight:600;
      margin-bottom:2.5rem;
  }

  /* .ktjId{
  } */

  .signupMess_idIntial__32I1_{
    color:#1f1863cf;
  }

  .signupMess_note__2QwTu{
    width:10rem;
    margin:auto;
    font-weight:500;
    font-size:1.2rem;
  }

  .signupMess_explore__1V49t{
   padding-top:1.5rem;
   padding-bottom:1.5rem;
   font-weight:600;
   color:#1F1863;
  }

.Schedule2_Schedule__35bzw {
  background-image: linear-gradient(to bottom, #211b58, #332d62);
  color: #b8b1d8;
}
.Schedule2_Schedule_Body__3v_St {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transform: translateY(-5%);
          transform: translateY(-5%);
  justify-content: space-evenly;
  padding-top: 9rem;
}
.Schedule2_Schedule_Heading__3ohnQ {
  text-align: center;
  font-size: 4rem;
  font-weight: bolder;
  margin-top: 6rem;
}
/*
.Schedule_TitleImage {
   transform: translateY(-30%); 
}
*/
.Schedule2_Schedule_TitleImage__2O-fH img {
  width: 30rem;
  height: 10rem;
  margin-bottom: 3rem;
  object-fit: cover;
}

.Schedule2_Schedule_Divider__kI9Fw {
  height: 10rem;
  width: 100%;
  /* transform: translateY(-140%); */
  background-color: #b8b1d8;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: space-evenly;
  align-items: center;
}
.Schedule2_Schedule_Divider__kI9Fw img {
  width: 9rem;
  height: 4.5rem;
}
.Schedule2_Schedule_DateTry__2Zct8 {
  display: flex;
  width: 100%;
  transition: 0.5s ease-in-out;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  margin-bottom: 2rem;
  /* transform: translateY(-160%); */
}

.Schedule2_Schedule_DateTry_DateTab__vKJPt {
  padding: 0.5rem;
  transition: 0.5s ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3), -5px -5px 5px #1f176283;
  border-radius: 15px;
  margin: 0 2rem;
  font-size: 4rem;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #b8b1d8;
}
.Schedule2_Schedule_DateTry__2Zct8:hover .Schedule2_Schedule_DateTry_DateTab__vKJPt:hover {
  background-color: #474444;
  border: 2px solid #474444;
}
.Schedule2_Schedule_CardsContainer__3MCZR {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  /* margin-bottom: 2rem; */
  /* transform: translateY(-20%); */
}
.Schedule2_Schedule_CardsContainer_left__2ddn_ {
  width: 60%;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  box-sizing: border-box;
  /* justify-content: end; */
  display: flex;
  overflow-y: auto;
}
.Schedule2_Schedule_CardsContainer_left__2ddn_::-webkit-scrollbar {
  width: 20px;
}
.Schedule2_Schedule_CardsContainer_left__2ddn_::-webkit-scrollbar-track :horizontal {
  visibility: hidden;
  border: none;
}
.Schedule2_Schedule_CardsContainer_left__2ddn_::-webkit-scrollbar-track-piece:vertical {
  border: 2px solid rgb(127, 192, 235);
  border-radius: 10px;
}
.Schedule2_Schedule_CardsContainer_left__2ddn_::-webkit-scrollbar-thumb {
  background-color: rgb(127, 192, 235);
  border-radius: 10px;
}
.Schedule2_Schedule_CardsContainer_left__2ddn_::-webkit-scrollbar-corner {
  visibility: hidden;
}
.Schedule2_Schedule_MainTataProjectCardCnt__1N_L3 {
  width: 40%;
  padding: 1rem;
  height: 80vh;
  box-sizing: border-box;
}
.Schedule2_Schedule_majorCard__1yOmU {
  display: flex;
  margin: 0rem auto;
  flex-direction: column;
  align-items: center;
  height: 90%;
  padding: 1rem 2rem;
  background-color: #5a5196;
  border-radius: 20px;
  justify-content: center;
  width: 50%;
}
.Schedule2_Schedule_MainTataProjectCard__3Oh3i img {
  width: 90%;
  height: 40%;
}
.Schedule2_Schedule_majorCardImageCnt__vOWi4 {
  width: 12rem;
  height: 12rem;
}
.Schedule2_Schedule_majorCardImageCnt__vOWi4 img {
  width: 105%;
  height: 110%;
}
.Schedule2_majorCardDate__19IDJ {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.Schedule2_HowToUseAirmeet__3ByUQ {
  padding: 1rem;
  text-align: center;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  /* background-image: linear-gradient(to bottom, #211b58, #332d62); */
  background-color: #5a5196d8;
}

.Schedule2_HowToUseAirmeet__3ByUQ a {
  color: #b8b1d8;
  text-decoration: none;
}
.Schedule2_majorCardTime__32mMl {
  margin-bottom: 0.4rem;
}

.Schedule2_joinbtn__1CYTY {
  text-decoration: none;
  display: block;
  width: 4.5rem;
  margin-top: 1rem;
  padding: 0.7rem 1.8rem;
  background-color: #f9828a;
  color: #381e65;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-weight: 500;
  margin-left: auto;
}
.Schedule2_presented__1nZUc {
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem;
}

.Schedule2_imagecnt__2fGBv {
  width: 7.5rem;
  height: 4rem;
}

@media screen and (max-width: 800px) {
  .Schedule2_Schedule_MainTataProjectCardCnt__1N_L3,
  .Schedule2_Schedule_CardsContainer_left__2ddn_ {
    width: 100%;
    height: auto;
  }

  .Schedule2_Schedule_CardsContainer__3MCZR {
    flex-direction: column;
  }

  .Schedule2_Schedule_majorCard__1yOmU {
    width: 90%;
    flex-direction: row;
    margin: 1rem auto;
  }
  .Schedule2_Schedule_MainTataProjectCard__3Oh3i img {
    width: 90%;
    height: 40%;
  }

  .Schedule2_Schedule_DateTry__2Zct8 {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

  .Schedule2_Schedule_DateTry_DateTab__vKJPt {
    padding: 0.5rem;
    color: #b8b1d8;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3), -5px -5px 5px #1f176283;
    border-radius: 15px;
    font-size: 2rem;
    margin: 0rem 1rem;
    font-weight: bold;
    border: 2px solid #b8b1d8;
  }
  .Schedule2_Schedule_DateTry__2Zct8:hover .Schedule2_Schedule_DateTry_DateTab__vKJPt:hover {
    background-color: #474444;
    font-size: 2.2rem;
    border: none;
  }
  .Schedule2_Schedule_Heading__3ohnQ {
    text-align: center;
    font-size: 3rem;
    font-weight: bolder;
    color: #b8b1d8;
  }
  .Schedule2_Schedule_Divider__kI9Fw {
    flex-wrap: wrap;
    height: 100%;
  }

  .Schedule2_Schedule_Divider__kI9Fw img {
    width: 2.5rem;
    height: 1.5rem;
    padding: 1rem 1rem;
  }
}
.Schedule2_workshops__RfQWh {
  width: 100%;
  display: flex;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.Schedule2_workshops_row__19q0V {
  font-size: 2.5rem;
  font-weight: bold;
  background-color: #5a5196;
  text-align: center;
  width: 20%;
  padding: 5rem 2rem;
  border-radius: 1rem;
  margin-left: 6rem;
  margin-right: 2rem;
}
.Schedule2_workshops_images__EB7c8 {
  display: flex;
  width: 60%;
  justify-content: space-around;
  background-color: #5a5196;
  border-radius: 1rem;
  text-align: center;
  font-weight: 800;
}

.Schedule2_workshops_image__1KYQ_ {
  padding: 2rem 0;
}
.Schedule2_workshops_image__1KYQ_ img {
  border-radius: 0.5rem;
  height: 15vh;
  width: calc(15vh);
}
.Schedule2_workshops_image__1KYQ_ a {
  color: #b8b1d8;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .Schedule2_workshops__RfQWh {
    width: 100%;
    display: block;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .Schedule2_workshops_row__19q0V {
    font-size: 1.2rem;
    margin-left: 30%;
    width: 30%;
    padding: 1rem 1rem;
    margin-bottom: 3rem;
    border-radius: 1.5rem;
  }
  .Schedule2_workshops_images__EB7c8 {
    display: block;
    width: 90%;
    margin-left: 5%;
    /* overflow-x: scroll; */
    /* margin-right: 1rem; */
  }
  .Schedule2_workshops_images__EB7c8::after {
    content: "";
    display: table;
    clear: both;
  }
  .Schedule2_workshops_image__1KYQ_ {
    float: left;
    width: 50%;
  }
  .Schedule2_workshops_image__1KYQ_ img {
    height: 10vh;
    width: calc(10vh);
  }
}

.ScheduleCard_ScheduleCard__2k6iG {
  width: 80%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 1rem;
  background-color: #5a5196;
  display: flex;
  margin: 1rem 0;
}

.ScheduleCard_ScheduleCard_ImgCnt__2_4ol {
  width: 8rem;
  height: 8rem;
  box-sizing: border-box;
}
.ScheduleCard_ScheduleCard_ImgCnt__2_4ol img {
  width: 90%;
  height: 100%;
}
.ScheduleCard_ScheduleCard_Host__5-BGd {
  font-weight: bold;
  border-right: 1px solid #b8b1d8;
  padding-right: 0.5rem;
  min-width: 50%;
  padding-bottom: 0.5rem;
}
.ScheduleCard_InfoCnt__2pRCW {
  width: 80%;
  color: #b8b1d8;
  box-sizing: border-box;
}
.ScheduleCard_InfoCnt_time__1jb-m {
  padding: 0.5rem 0;
  display: flex;
}
.ScheduleCard_InfoCnt_timeCnt__1-Hvz {
  padding-left: 0.5rem;
}

.ScheduleCard_ScheduleCard_company__KRvgz {
  font-weight: bold;
  margin-bottom: 0.4rem;
}
.ScheduleCard_joinbtn__pX0n4 {
  text-decoration: none;
  display: block;
  width: 4.5rem;
  margin-top: 1rem;
  padding: 0.7rem 1.8rem;
  background-color: #f9828a;
  color: #381e65;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-weight: 500;
  margin-left: auto;
}

@media screen and (max-width: 400px) {
  .ScheduleCard_ScheduleCard__2k6iG {
    width: 80%;
    margin: 1rem auto;
    font-size: 0.8rem;
  }
  .ScheduleCard_InfoCnt_timeCnt__1-Hvz {
    padding: 0;
  }
  .ScheduleCard_ScheduleCard_Host__5-BGd {
    padding: 0;
    border: none;
  }
  .ScheduleCard_ScheduleCard_ImgCnt__2_4ol {
    width: 30%;
    box-sizing: border-box;
  }
  .ScheduleCard_ScheduleCard_ImgCnt__2_4ol img {
    width: 90%;
    height: 100%;
  }
  .ScheduleCard_InfoCnt__2pRCW {
    width: 70%;
    color: #b8b1d8;
    box-sizing: border-box;
  }
}

.SideDrawer_SideDrawer__1Chls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /* height: 100%; */
  padding: 0.5rem;
  width: 100%;
}

.SideDrawer_SideDrawer__1Chls img {
  width: 100px;
  height: 50px;
}
.SideDrawer_SideDrawer__1Chls i {
  color: #84a1d2;
  font-size: 2rem;
}
.SideDrawer_SideDrawer_hmbrgIcon__38_Q9 {
  padding: 1rem;
  padding-right: 2rem;
}
.SideDrawer_SideDrawer_Drawer__285cG {
  position: fixed;
  display: flex;
  height: 100vh;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-items: start;
  align-items: center;
  top: 0;
  right: 0;
  background-color: #05040f;
  color: white;
  width: 65%;
  transition: 0.8s ease-in-out;
}
.SideDrawer_SideDrawer_Drawer_header__2RArl {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}
.SideDrawer_SideDrawer_Drawer_hide__3zay6 {
  -webkit-transform: translateX(200%);
          transform: translateX(200%);
}
.SideDrawer_SideDrawer_Drawer_header__2RArl button {
  outline: none;
  padding: 1rem 1rem;
  background-color: #0a0632;
  color: #84a1d2;
  font-size: 1.2rem;
}
.SideDrawer_SideDrawer_Drawer_header__2RArl > div {
  margin: 0rem 0.2rem;
}
.SideDrawer_SigninBtn__2CfZD {
  border: none;
  border-radius: 20px 0px 0px 20px;
  border-right: 1px solid #84a1d2;
}
.SideDrawer_SignupBtn__2RQi3 {
  border: none;
  border-radius: 0px 10px 10px 0px;
  border-left: 1px solid #84a1d2;
}
.SideDrawer_SideDrawer_Drawer__285cG > div {
  width: 100%;
}
.SideDrawer_SideDrawer_Drawer_Tabs__AANbf {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
}
.SideDrawer_SideDrawer_Drawer_Tabs__AANbf > div {
  padding: 1rem;
  text-align: start;
  font-weight: bold;

  font-size: 1.3rem;
  width: 100%;
}

.SideDrawer_SideDrawer_Drawer_Tabs__AANbf a {
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border-bottom: 0.5px solid white;
}
.SideDrawer_SideDrawer_Drawer_Tabs__AANbf > div:hover {
  color: #84a1d2;
}

.bgvideo {
  position: relative;
  height: 100vh;
  width: 100%;
  background-position: center;
  top: 0%;
  left: 0%;
  opacity: 1;
}
.bgvideo video {
  height: 100vh;
}
.bgvideo_content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Coming_back_button__1APsp {
  position: absolute;
  z-index: 10;
  top: 90vh;
  left: 3%;
  padding: 0.5rem 2rem;
  border-radius: 9rem;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: #c4b9e2;
  border: 0.1em solid #c4b9e2;
}
.Coming_back_button__1APsp:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.Coming_back_button__1APsp:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.Footer_Footer__3JINQ {
  width: 100vw;
  height: 35rem;
  position: relative;
  bottom: 0;
  background-color: #b8b1d8;
  padding: 2rem 0 1rem;
  --col-primary: #4b27a9;
  box-sizing: border-box;
  font-family: Quicksand;
  color: var(--col-primary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 90%;
}

.Footer_Footer__3JINQ > .Footer_middlediv__38kmk {
  display: flex;
  justify-content: flex-start;
  /* padding: 0rem 2rem; */
  flex: 0.8 1;
}

.Footer_Footer__3JINQ a {
  text-decoration: none;
  color: inherit;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Footer_Footer__3JINQ a:hover {
  opacity: 0.8;
}

.Footer_Homepagelinks__2Q4Zm,
.Footer_Otherlinks__2_p_P {
  border-left: 1px solid var(--col-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
  margin-left: 1.5em;
  height: 100%;
}

.Footer_Otherlinks__2_p_P {
  flex: 1 1;
}

.Footer_Homepagelinks__2Q4Zm a,
.Footer_Otherlinks__2_p_P a {
  padding: 0rem 0.6rem;
}

.Footer_Homepagelinks__2Q4Zm > *:not(:last-child) {
  height: 23%;
}

.Footer_thirddiv__1fqhU {
  border-top: 1px solid var(--col-primary);
  font-weight: 600;
}

.Footer_thirddiv__1fqhU {
  padding: 0.8rem 1.5rem;
}

.Footer_title__1Y_ym {
  font-size: 2.2rem;
  margin: 0rem 1.5rem;
  border-bottom: 1px solid var(--col-primary);
  width: 30%;
}

.Footer_Footer__3JINQ ul {
  list-style-type: none;
}

.Footer_Activitieslinks__2uAsu {
  display: flex;
}

.Footer_Activitieslinks__2uAsu > a {
  width: 5em;
  box-sizing: border-box;
}

.Footer_Activitiessublinks__27fOR li {
  font-weight: normal;
  margin-bottom: 0.3rem;
}

.Footer_letsconnect__3qwTs,
.Footer_register__1JMNp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Footer_register__1JMNp {
  margin-top: 1rem;
}

.Footer_connectlinks__3LNZc {
  width: 50%;
  display: flex;
  justify-content: space-between;
  font-size: 200%;
}

.Footer_register__1JMNp input {
  background-color: transparent;
  outline: none;
  border: 1px solid var(--col-primary);
  flex: 0.8 1;
  padding: 0.2rem;
  width: 40%;
  font-size: inherit;
}

.Footer_register__1JMNp input::-webkit-input-placeholder {
  color: var(--col-primary);
  opacity: 0.7;
}

.Footer_register__1JMNp input:-ms-input-placeholder {
  color: var(--col-primary);
  opacity: 0.7;
}

.Footer_register__1JMNp input::placeholder {
  color: var(--col-primary);
  opacity: 0.7;
}

@media (max-width: 876px) {
  .forgetOuter {
    background-image: url(/static/media/Background.79e73c30.png);
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .forgetEmail {
    position: absolute;
    top: 30%;
    left: 20%;
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 10px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 5px solid rgb(196, 134, 238);
    outline: none;
  }

  .forgetEmail::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .forgetEmail:-ms-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .forgetEmail::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .forgetFormSubmit {
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 4px;
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin: 0 auto;
  }
}

.homepagemobileApp {
  text-align: center;
  background-color: white;
  scroll-behavior: smooth;
}

.homepagemobileApp > div > img {
  width: 100vw;
  display: block;
}

.room_description-enter {
  height: 0;
}
.room_description-enter-active,
.room_description-enter-done {
  height: var(--descheight);
  transition: height 1s;
}

.room_description-enter-active > div,
.room_description-enter-done > div {
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform: translateY(0%) !important;
          transform: translateY(0%) !important;
  z-index: 0 !important;
}
/* 
.room_description-enter-active ~ div:nth-of-type(even),
.room_description-enter-done ~ div:nth-of-type(even) {
  transition: transform 1s;
  transform: translateY(var(--descheight));
} */

.room_description-exit,
.room_description-exit-active {
  height: 0;
  transition: height 1s;
}

.room_description-exit > div,
.room_description-exit-active > div {
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  z-index: 0 !important;
}
/* 
.room_description-exit ~ div:nth-of-type(even) {
  transition: transform 1s;
} */

.Room_Room__2JhrN {
  position: relative;
  width: 100%;
  z-index: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.Room_Room__2JhrN * {
  box-sizing: border-box;
}
.Room_FrameContent__2bGYh {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
}
.Room_Frame_Name__3Vl7i {
  padding: 0.2rem 0.5rem;
  width: 70%;
  border-radius: 20px;
  background-color: pink;
  font-size: 4vw;
  font-weight: bold;
  text-transform: uppercase;
}
.Room_Frame_main_gif__1RFte {
  width: 70%;
}
.Room_Frame_second_gif__268vS {
  width: 44vw;
  max-width: 100%;
  height: 44vw;
  position: relative;
}
.Room_Frame_second_gif__268vS video {
  width: 150%;
  left: -25%;
  top: -25%;
  position: relative;
}

.Room_Frame_description__1aKGF {
  background-color: #4d2aaa;
  width: 100%;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  padding: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  z-index: -1;
  box-sizing: border-box;
}

.Room_Frame_content__3uwCR {
  color: #f2eff9;
  text-align: justify;
  /* max-height: calc(calc(80vh - 250px) - 50vw); */
  max-height: 50vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem;
  margin-top: 1rem;
  scrollbar-width: auto;
  scrollbar-color: #f2eff9 #392d76 !important;
}

.Room_Frame_content__3uwCR * {
  margin: 0;
  margin: initial;
  padding: 0;
  padding: initial;
}

.Room_Frame_content__3uwCR ol {
  list-style-position: inside;
}

.Room_Frame_content__3uwCR a {
  text-align: right;
  display: block;
  color: inherit;
}

.Room_Frame_content__3uwCR input {
  background-color: #4d2aaa;
  border: 1px solid #f2eff9;
  padding: 0.5rem;
  display: inline-block;
  color: inherit;
}

.Room_Frame_content__3uwCR::-webkit-scrollbar {
  border-radius: 60px !important;
  width: 1rem !important;
  display: block !important;
  border: none !important;
}

.Room_Frame_content__3uwCR::-webkit-scrollbar-track {
  background: transparent !important;
}

.Room_Frame_content__3uwCR::-webkit-scrollbar-thumb {
  background-color: #f2eff9 !important;
}

.Table_table__Zc4Ow {
	position: absolute;
	z-index: 10;
	top: 20vh;
	left: 1%;
	font-size: 2vh;
	-webkit-animation-name: Table_TableAnimation__28yL0;
	        animation-name: Table_TableAnimation__28yL0;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
}
.Table_white__KEHu_ {
	color: white;
	padding-right: 0.5rem;
	text-align: right;
}
.Table_dcolor__2PAgt {
	color: rgb(199, 168, 230);
	text-align: left;
}
.Table_buttonStyle__2dAy5 {
	color: white;
	margin-top: 1rem;
	margin-left: 4rem;
	background-color: transparent;
	padding: 0.5rem 2.5rem;
	cursor: pointer;
	border-radius: 3rem;
	border: 2px solid rgb(199, 168, 230);
	font-size: 1.8vh;
	position: absolute;
	top: 30vh;
	left: 15%;
}
.Table_registered__14r05 {
	padding-right: 0.5rem;
	color: white;
	vertical-align: 0px;
	text-align: right;
	width: 20%;
}
.Table_eventTable__mXykd {
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}
.Table_scrollbar__akA3g {
	max-height: 10vh;
	overflow-y: auto;
	position: absolute;
	padding-right: 0.5rem;
}
.Table_scrollbar__akA3g::-webkit-scrollbar {
	width: 15px;
}
.Table_scrollbar__akA3g::-webkit-scrollbar-track :horizontal {
	visibility: hidden;
	border: none;
}
.Table_scrollbar__akA3g::-webkit-scrollbar-track-piece:vertical {
	border: 1.9px solid rgb(127, 192, 235);
	border-radius: 5px;
}
.Table_scrollbar__akA3g::-webkit-scrollbar-thumb {
	background-color: rgb(127, 192, 235);
	border-radius: 5px;
}
.Table_scrollbar__akA3g::-webkit-scrollbar-corner {
	visibility: hidden;
}

.Table_color1__2n_nv {
	color: #1a1765;
	padding: 0.2rem 0rem 0.2rem 0.5rem;
	background-color: #a79cb4;
	width: 10%;
	text-align: left;
}
.Table_color2__q8_5H {
	padding: 0.2rem 0rem 0.2rem 0.5rem;
	color: #1a1765;
	background-color: #e3d0ff;
	width: 10%;
	text-align: left;
}
@-webkit-keyframes Table_TableAnimation__28yL0 {
	0% {
		opacity: 0;
		top: 10vh;
	}
	52% {
		opacity: 0;
		top: 15vh;
	}
	100% {
		opacity: 1;
		top: 25vh;
	}
}
@keyframes Table_TableAnimation__28yL0 {
	0% {
		opacity: 0;
		top: 10vh;
	}
	52% {
		opacity: 0;
		top: 15vh;
	}
	100% {
		opacity: 1;
		top: 25vh;
	}
}

@media (max-width: 876px) {
  .outer {
    background-image: url(/static/media/Background.79e73c30.png);
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .signOuter {
    /* text-align: center; */
    padding-top: 20vh;
  }

  .formOuter {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .formInput {
    font-size: 1rem;
    color: rgb(199, 168, 230);
    width: 70vw;
    background-color: transparent;
    /* margin-bottom: 0.6rem; */
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid rgb(196, 134, 238);
    outline: none;
    margin-top: 1rem;
  }
  .formInput input::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput input:-ms-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .formInput::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
  }

  .formInput:-ms-input-placeholder {
    color: rgb(199, 168, 230);
  }

  .formInput::placeholder {
    color: rgb(199, 168, 230);
  }

  .formSubmit {
    border-radius: 6px;
    font-size: 1rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 5px 20px;
  }
  .forgotPassword {
    border-radius: 6px;
    margin-top: 10px;
    font-size: 0.8rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 5px 20px;
  }

  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.8rem;
    color: red;
    margin-bottom: 1rem;
  }
}

@media (max-width: 876px) {
  .formOuter {
    background-image: url(/static/media/Background.79e73c30.png);
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form {
    position: absolute;
    z-index: 10;
    top: 15%;
    left: 8%;
  }

  .formInput {
    font-size: 1rem;
    color: rgb(199, 168, 230);
    width: 70vw;
    background-color: transparent;
    margin-bottom: 0.3rem;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid rgb(196, 134, 238);
    outline: none;
  }
  .heading {
    border-bottom: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  .formInput input {
    font-size: 0.8rem;
    color: rgb(199, 168, 230);
    padding-left: 4px;
    width: 64vw;
    background-color: transparent;
    border: none;
    outline: none;
  }
  .formInput input::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput input:-ms-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .formInput::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
  }

  .formInput:-ms-input-placeholder {
    color: rgb(199, 168, 230);
  }

  .formInput::placeholder {
    color: rgb(199, 168, 230);
  }

  .formSubmit {
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 4px;
    position: relative;
    margin: 10px 0;
  }

  .formInput2 {
    font-size: 1rem;
    color: rgb(199, 168, 230);
    width: 34vw;
    background-color: transparent;
    margin-bottom: 0.3rem;
    margin-right: 10px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid rgb(196, 134, 238);
    outline: none;
    /* float: left; */
    display: inline-block;
  }
  .confirmpass {
    float: none;
    width: 40vw;
  }
  .formInput2 input {
    font-size: 0.8rem;
    color: rgb(199, 168, 230);
    padding-left: 5px;
    width: 27vw;
    background-color: transparent;
    border: none;
    outline: none;
  }
  .confirmpass input {
    width: 33vw;
  }
  .formInput2 input::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput2 input:-ms-input-placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }
  .formInput2 input::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .formInput2::-webkit-input-placeholder {
    color: rgb(199, 168, 230);
  }

  .formInput2:-ms-input-placeholder {
    color: rgb(199, 168, 230);
  }

  .formInput2::placeholder {
    color: rgb(199, 168, 230);
  }

  .phone {
    width: 47vw;
  }
  .phone input {
    width: 39vw;
  }
  .Gender {
    width: 20vw;
  }
  .Gender input {
    width: 13vw;
  }
  .red-text-field {
    border-bottom: 3px solid red;
  }

  .error-message {
    font-size: 0.55rem;
    color: red;
  }
}

* {
  margin: 0px;
  padding: 0px;
}

.signupmess_background__3T_uL {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/Background.79e73c30.png);
  padding: 0;
  margin: 0;
}

.signupmess_msgWrapper__22qGh {
  position: relative;
  top: 30%;
  left: 10%;
  z-index: 10;
  background-color: #bdb3dc;
  width: 80%;
  text-align: center;
  border-radius: 1rem;
}
.signupmess_regLine__1d8H0 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #1f1863;
  font-weight: 600;
}

.signupmess_ktjName__3JpKG {
  font-size: 2rem;
  color: #1f1863;
  padding-bottom: 1.5rem;
}
.signupmess_year__3HtI3 {
  font-size: 2rem;
  color: white;
}

.signupmess_Idbox__18Q_v {
  background-color: white;
  font-size: 1rem;
  width: 15rem;
  margin: auto;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-radius: 0.3rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
}

/* .ktjId{
    } */

.signupmess_idIntial__2tI27 {
  color: #1f1863cf;
}

.signupmess_note__3hFML {
  width: 10rem;
  margin: auto;
  font-weight: 500;
  font-size: 1.2rem;
}

.signupmess_explore__3Iy6B {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-weight: 600;
  color: #1f1863;
}

