.Room {
  position: relative;
  width: 100%;
  z-index: 1;
  transform: translateY(0%);
}
.Room * {
  box-sizing: border-box;
}
.FrameContent {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
}
.Frame_Name {
  padding: 0.2rem 0.5rem;
  width: 70%;
  border-radius: 20px;
  background-color: pink;
  font-size: 4vw;
  font-weight: bold;
  text-transform: uppercase;
}
.Frame_main_gif {
  width: 70%;
}
.Frame_second_gif {
  width: 44vw;
  max-width: 100%;
  height: 44vw;
  position: relative;
}
.Frame_second_gif video {
  width: 150%;
  left: -25%;
  top: -25%;
  position: relative;
}

.Frame_description {
  background-color: #4d2aaa;
  width: 100%;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  padding: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  transform: translateY(-100%);
  z-index: -1;
  box-sizing: border-box;
}

.Frame_content {
  color: #f2eff9;
  text-align: justify;
  /* max-height: calc(calc(80vh - 250px) - 50vw); */
  max-height: 50vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem;
  margin-top: 1rem;
  scrollbar-width: auto;
  scrollbar-color: #f2eff9 #392d76 !important;
}

.Frame_content * {
  margin: initial;
  padding: initial;
}

.Frame_content ol {
  list-style-position: inside;
}

.Frame_content a {
  text-align: right;
  display: block;
  color: inherit;
}

.Frame_content input {
  background-color: #4d2aaa;
  border: 1px solid #f2eff9;
  padding: 0.5rem;
  display: inline-block;
  color: inherit;
}

.Frame_content::-webkit-scrollbar {
  border-radius: 60px !important;
  width: 1rem !important;
  display: block !important;
  border: none !important;
}

.Frame_content::-webkit-scrollbar-track {
  background: transparent !important;
}

.Frame_content::-webkit-scrollbar-thumb {
  background-color: #f2eff9 !important;
}
