.Description {
  color: #c4b9e2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  padding: 1em 1em;
  max-height: 12em;
  overflow-y: auto;
  display: inline-block;
  scrollbar-width: auto;
  scrollbar-color: #c4b9e2 #392d76;
  font-size: 0.9em;
  border: 1rem solid rgba(255, 255, 255, 0.1);
}
.Description::-webkit-scrollbar {
  border-radius: 60px;
  width: 10rem;
  display: block;
}

.Description::-webkit-scrollbar-track {
  background: transparent;
}

.Description::-webkit-scrollbar-thumb {
  outline: none;
  border-radius: 60px;
  background: #c4b9e2;
}
.Description::-webkit-scrollbar-track-piece:end,
.Description::-webkit-scrollbar-track-piece:start {
  background: transparent;
  border: 10px solid #c4b9e2;
  border-radius: 60px;
  margin-bottom: 20rem;
}
