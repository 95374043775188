.table {
	position: absolute;
	z-index: 10;
	top: 20vh;
	left: 1%;
	font-size: 2vh;
	animation-name: TableAnimation;
	animation-duration: 2s;
	animation-timing-function: ease-in;
}
.white {
	color: white;
	padding-right: 0.5rem;
	text-align: right;
}
.dcolor {
	color: rgb(199, 168, 230);
	text-align: left;
}
.buttonStyle {
	color: white;
	margin-top: 1rem;
	margin-left: 4rem;
	background-color: transparent;
	padding: 0.5rem 2.5rem;
	cursor: pointer;
	border-radius: 3rem;
	border: 2px solid rgb(199, 168, 230);
	font-size: 1.8vh;
	position: absolute;
	top: 30vh;
	left: 15%;
}
.registered {
	padding-right: 0.5rem;
	color: white;
	vertical-align: 0px;
	text-align: right;
	width: 20%;
}
.eventTable {
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}
.scrollbar {
	max-height: 10vh;
	overflow-y: auto;
	position: absolute;
	padding-right: 0.5rem;
}
.scrollbar::-webkit-scrollbar {
	width: 15px;
}
.scrollbar::-webkit-scrollbar-track :horizontal {
	visibility: hidden;
	border: none;
}
.scrollbar::-webkit-scrollbar-track-piece:vertical {
	border: 1.9px solid rgb(127, 192, 235);
	border-radius: 5px;
}
.scrollbar::-webkit-scrollbar-thumb {
	background-color: rgb(127, 192, 235);
	border-radius: 5px;
}
.scrollbar::-webkit-scrollbar-corner {
	visibility: hidden;
}

.color1 {
	color: #1a1765;
	padding: 0.2rem 0rem 0.2rem 0.5rem;
	background-color: #a79cb4;
	width: 10%;
	text-align: left;
}
.color2 {
	padding: 0.2rem 0rem 0.2rem 0.5rem;
	color: #1a1765;
	background-color: #e3d0ff;
	width: 10%;
	text-align: left;
}
@keyframes TableAnimation {
	0% {
		opacity: 0;
		top: 10vh;
	}
	52% {
		opacity: 0;
		top: 15vh;
	}
	100% {
		opacity: 1;
		top: 25vh;
	}
}
