.bgvideo {
  position: relative;
  height: 100vh;
  width: 100%;
  background-position: center;
  top: 0%;
  left: 0%;
  opacity: 1;
}
.bgvideo video {
  height: 100vh;
}
.bgvideo_content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
