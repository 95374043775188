.container {
  --left-width: 50%;
  position: absolute;
  height: 100%;
  display: flex;
  width: 100%;
  transform: scale(0.0168);
  top: -16.1%;
  left: 3.248%;
}

.left,
.right {
  height: 100%;
  display: inline-block;
  position: relative;
}

.left {
  width: var(--left-width);
  /* background-color: #292268; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right {
  width: calc(100% - var(--left-width));
  background-color: #5c3bb1;
  --window-height: 36%;
}

.window1 {
  height: var(--window-height);
  width: 60%;
  top: 5em;
  right: 0;
}

.window2 {
  height: var(--window-height);
  width: 60%;
  bottom: 9%;
  left: 7em;
}
