@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "AvenirLTStd-Black";
  src: url("./fonts/AvenirLTStd-Black.otf");
}
@font-face {
  font-family: "AvenirLTStd-Roman";
  src: url("./fonts/AvenirLTStd-Roman.otf");
}
@font-face {
  font-family: "Rheiborn_Sans_Clean";
  src: url("./fonts/Rheiborn_Sans_Clean.otf");
}

body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 1024px) {
  html {
    font-size: 80%;
  }
}

@media (min-width: 1440px) {
  html {
    font-size: 120%;
  }
}

@media (max-width: 876px) {
  html {
    font-size: 100%;
  }
}

.App {
  position: relative;
  /* height: 100vh; */
  width: 100vw;
  /* overflow: hidden; */
  z-index: 0;
}

#root {
  overflow: hidden;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.normal {
  transform: scale(0.1);
  --left-adjust: 50.3938%;
  --top-adjust: 27.7248%;
}

.zoomin {
  transform: scale(5.95);
  transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
}

.zoomout {
  transform: scale(0.1);
  transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.changeframe {
  transition: transform-origin 1.3s ease-in-out, top 1.3s ease-in-out,
    left 1.3s ease-in-out;
}

* {
  font-size: inherit;
}

.frame__content-enter {
  transform: translateX(150%);
}

.frame__content-enter-active {
  transform: translateX(0);
  transition: all 1s;
}

.frame__content-exit {
  opacity: 1;
}

.frame__content-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.5s;
}

.frame__content-exit-done {
  opacity: 0;
}
