.SideDrawer {
  width: 80%;
  display: none;
  background-color: #2e2869;
  color: #c4b9e2;
  position: absolute;
  left: 0;
  min-height: 100vh;
  top: 0;
  padding: 1rem;
  transition: 0.4s ease-in-out;

  flex-direction: column;
  justify-content: space-between;
}
.KTJHeading {
  text-align: center;
  letter-spacing: 10px;
  font-weight: bold;
  width: 80%;
  box-sizing: border-box;
  display: inline-block;
  padding: 1rem;
  background-color: white;
  color: black;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
}

.SideDrawer > div {
  padding: 1rem;
}
.SideDrawerCloseBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .SideDrawer {
    display: flex;
  }

  .Hide {
    transform: translateX(-100%);
  }

  .Show {
    transform: translateX(0%);
  }
}
