@media (max-width: 876px) {
  .forgetOuter {
    background-image: url("../images/Background.png");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .forgetEmail {
    position: absolute;
    top: 30%;
    left: 20%;
    font-size: 1.1rem;
    color: rgb(199, 168, 230);
    padding: 5px;
    background-color: transparent;
    margin-bottom: 10px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 5px solid rgb(196, 134, 238);
    outline: none;
  }

  .forgetEmail::placeholder {
    color: rgb(199, 168, 230);
    outline: none;
  }

  .forgetFormSubmit {
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 4px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
  }
}
