.SideDrawer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /* height: 100%; */
  padding: 0.5rem;
  width: 100%;
}

.SideDrawer img {
  width: 100px;
  height: 50px;
}
.SideDrawer i {
  color: #84a1d2;
  font-size: 2rem;
}
.SideDrawer_hmbrgIcon {
  padding: 1rem;
  padding-right: 2rem;
}
.SideDrawer_Drawer {
  position: fixed;
  display: flex;
  height: 100vh;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-items: start;
  align-items: center;
  top: 0;
  right: 0;
  background-color: #05040f;
  color: white;
  width: 65%;
  transition: 0.8s ease-in-out;
}
.SideDrawer_Drawer_header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}
.SideDrawer_Drawer_hide {
  transform: translateX(200%);
}
.SideDrawer_Drawer_header button {
  outline: none;
  padding: 1rem 1rem;
  background-color: #0a0632;
  color: #84a1d2;
  font-size: 1.2rem;
}
.SideDrawer_Drawer_header > div {
  margin: 0rem 0.2rem;
}
.SigninBtn {
  border: none;
  border-radius: 20px 0px 0px 20px;
  border-right: 1px solid #84a1d2;
}
.SignupBtn {
  border: none;
  border-radius: 0px 10px 10px 0px;
  border-left: 1px solid #84a1d2;
}
.SideDrawer_Drawer > div {
  width: 100%;
}
.SideDrawer_Drawer_Tabs {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
}
.SideDrawer_Drawer_Tabs > div {
  padding: 1rem;
  text-align: start;
  font-weight: bold;

  font-size: 1.3rem;
  width: 100%;
}

.SideDrawer_Drawer_Tabs a {
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border-bottom: 0.5px solid white;
}
.SideDrawer_Drawer_Tabs > div:hover {
  color: #84a1d2;
}
