.back_button {
	position: absolute;
	z-index: 10;
	top: 90vh;
	left: 3%;
	padding: 0.5rem 2rem;
	border-radius: 9rem;
	cursor: pointer;
	outline: none;
	background-color: transparent;
	color: #c4b9e2;
	border: 0.1em solid #c4b9e2;
}
.back_button:hover {
	transform: translateY(-2px);
}
.back_button:active {
	transform: translateY(2px);
}
