@media (min-width: 876px) {
    .signOuter {
        position: absolute;
        top: 45%;
        left: 60%;
        z-index: 10;
    }

    .formOuter {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .formSubmit {
        border-radius: 6px;
        width: fit-content;
        font-size: 1rem;
        background-color: transparent;
        color: rgb(214, 185, 226);
        border: 3px solid rgb(220, 195, 236);
        padding: 10px;
    }

    .red-text-field {
        border-bottom: 3px solid red;
    }

    .Email {

        font-size: 1.1rem;
        color: rgb(199, 168, 230);
        padding: 5px;
        width: 440px;
        background-color: transparent;
        margin-bottom: 10px;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        border-bottom: 5px solid rgb(196, 134, 238);
        outline: none;
    }

    .Email::placeholder {
        color: rgb(199, 168, 230);
        outline: none;
    }
}